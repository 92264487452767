import { useMemo } from 'react'

import { RoutePaths } from 'config/routes'
import getNormetAnalyticsPortalURL from 'utils/getNormetAnalyticsPortalURL'
import getNormetLandingURL from 'utils/getNormetLandingURL'
import getNormetStoreURL from 'utils/getNormetStoreURL'
import getSiteURL from 'utils/getSiteURL'
import { hasBusinessUnitAccessToAnalyticsPortal } from 'utils/hasAccessToAnalyticsPortal'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

export type SiteKey = TranslationKeys['common'] extends infer LINK
  ? LINK extends `components.header.sites.${infer KEY}.${string}`
    ? KEY
    : never
  : never

interface Options {
  /**
   * @default false
   */
  addUTMParams?: boolean
}

export const UTM_PARAMS = {
  utm_source: 'portal',
  utm_medium: 'referral',
  utm_content: getSiteURL(),
}

function useNormetSitesLinks({ addUTMParams = false }: Options) {
  const { current } = useBusinessUnit()

  const hasAccessToAnalyticsPortal = hasBusinessUnitAccessToAnalyticsPortal(current)

  return useMemo(() => {
    const landingUrl = getNormetLandingURL()
    if (landingUrl && addUTMParams) {
      landingUrl.search = new URLSearchParams(UTM_PARAMS).toString()
    }

    const storeUrl = getNormetStoreURL()
    if (storeUrl && addUTMParams) {
      storeUrl.search = new URLSearchParams(UTM_PARAMS).toString()
    }

    const analyticsUrl = getNormetAnalyticsPortalURL(hasAccessToAnalyticsPortal)

    return {
      portal: { siteKey: 'portal', href: RoutePaths.equipmentOverview },
      store: { siteKey: 'store', href: storeUrl?.toString() || '' },
      analytics: { siteKey: 'analytics', href: analyticsUrl?.toString() || '' },
      landing: { siteKey: 'landing', href: landingUrl?.toString() || '' },
    } as const
  }, [addUTMParams, hasAccessToAnalyticsPortal])
}

export default useNormetSitesLinks
