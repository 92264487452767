import { Flex, HStack, List, ListItem, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useEffect } from 'react'

import Link from 'components/elements/Link'
import ConditionalRenderingLoggedIn from 'components/modules/Auth/ConditionalRendering/LoggedIn'
import NavigationButton from 'components/modules/Header/Navigation/NavigationButton'
import { RoutePaths } from 'config/routes'
import dynamicWithPreload from 'utils/dynamicWithPreload'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const ConnectedDrawer = dynamicWithPreload(
  () => import(/* webpackChunkName: "ConnectedDrawer" */ 'components/modules/Header/Navigation/ConnectedDrawer'),
)

const TAG = 'Navigation'

const styles = {
  nav: {
    display: { base: 'none', md: 'flex' },
    minW: '0',
    justifyContent: 'center',
    w: 'full',
  },
  list: {
    direction: 'row',
    maxW: 'desktop',
    px: { base: 0, '2xl': 10 },
    w: 'full',
    spacing: { base: '6', xl: '10' },
  },
  link: {
    color: 'primary.white',
    textDecoration: 'none',
  },
} as const satisfies SystemStyleInterpolation

const Navigation: FunctionComponent = () => {
  const { t } = useTranslation()
  const { categories } = useBusinessUnit()

  useEffect(() => {
    ConnectedDrawer.preload()
  }, [])

  return (
    <Flex data-testid={TAG} as="nav" {...styles.nav}>
      <ConditionalRenderingLoggedIn>
        <HStack as={List} {...styles.list}>
          <ListItem>
            <Link href={RoutePaths.equipmentOverview} {...styles.link} rel="noreferrer">
              {t('components.header.fleetOverview')}
            </Link>
          </ListItem>
          {categories && (
            <ListItem>
              <NavigationButton variant="link" drawer={<ConnectedDrawer categories={categories} />}>
                {t('components.header.connected.title')}
              </NavigationButton>
            </ListItem>
          )}
        </HStack>
      </ConditionalRenderingLoggedIn>
    </Flex>
  )
}

export default Navigation
